import { Success } from '@/assets/illustrations'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { AuthContext } from '@/providers/Auth/context'
import { useMutation } from '@apollo/client'
import { T, useTranslate } from '@tolgee/react'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const VALIDATE_EMAIL = gql(`
  mutation validateEmail($token: String!) {
    user: validateEmailByToken(token: $token) {
      validation
    }
  }
`)

export const VerifyEmail: React.FC = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const user = auth?.currentUser

  const [loading, setLoading] = useState(true)
  const token = location.search.split('?')[1]

  const [validateEmail] = useMutation(VALIDATE_EMAIL)

  validateEmail({ variables: { token } })
    .then(() => {
      setLoading(false)
    })
    .catch(() => {
      setLoading(false)
    })

  const content = (
    <div className="verify-email-popup-content max-md:w-full text-center">
      <div className="flex justify-center">
        <div className="mx-2 rounded p-2">
          <img src={Success} alt="success cloud" />
        </div>
      </div>

      <h1 className="text-lg font-medium">{t('components.validate.title')}</h1>

      <p className="flex text-gray-100 justify-center items-center m-1">
        {t('components.validate.firstInfo')}
      </p>

      <p className="flex text-gray-100 justify-center items-center m-1">
        {t('components.validate.secondInfo')}
      </p>

      <div className="flex justify-center items-center mt-3">
        <Button
          onClick={() =>
            navigate(user ? '/overview/subscriptions' : '/auth/login')
          }
        >
          <T keyName={'components.validate.btn'} />
        </Button>
      </div>
    </div>
  )

  return (
    <PopupSkeleton showClose={!loading} onClose={() => navigate('/')}>
      <div className="w-[480px] h-full p-10 max-md:p-4 max-md:w-full">
        {loading ? <Spinner /> : content}
      </div>
    </PopupSkeleton>
  )
}
