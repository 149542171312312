import { Forgot } from '@/assets/icons'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { T, useTranslate } from '@tolgee/react'
import { useNavigate } from 'react-router-dom'

export const SuccessForgotPassword: React.FC = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const content = (
    <div className="max-md:w-full text-center">
      <div className="flex justify-center items-center">
        <Forgot />
      </div>
      <p className="flex text-gray-100 justify-center items-center m-1">
        {t('components.forgot.success.info_first')}
      </p>
      <p className="flex text-gray-100 justify-center items-center m-1">
        {t('components.forgot.success.info_second')}
      </p>

      <div className="flex justify-center items-center mt-3">
        <Button onClick={() => navigate('/auth/login')}>
          <T keyName={'components.forgot.success.button'} />
        </Button>
      </div>
    </div>
  )

  return (
    <PopupSkeleton onClose={() => navigate('/')}>
      <div className="w-[480px] h-full p-10 max-md:p-4 max-md:w-full">
        {content}
      </div>
    </PopupSkeleton>
  )
}
