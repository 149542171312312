/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation moneyOut($userId: String!) {\n    money: moneyOut(userId: $userId)\n  }\n": types.MoneyOutDocument,
    "\n  mutation forgotPassword($email: String!) {\n    forgot: forgotPassword(email: $email)\n  }\n": types.ForgotPasswordDocument,
    "\n  mutation resetPassword($token: String!, $password: String!) {\n    password: changePassword(token: $token, password: $password) {\n      id\n    }\n  }\n": types.ResetPasswordDocument,
    "\n  mutation validateEmail($token: String!) {\n    user: validateEmailByToken(token: $token) {\n      validation\n    }\n  }\n": types.ValidateEmailDocument,
    "\n  query getBankDetails {\n    me {\n      bankDetails {\n        iban\n        bic\n        accountHolder\n        bankName\n      }\n      documentsStatus\n      firstDocumentType\n      firstDocumentStatus\n      ibanStatus\n    }\n  }\n": types.GetBankDetailsDocument,
    "\n  mutation updateUserBankDetails(\n    $bankName: String!\n    $iban: String!\n    $bic: String!\n    $accountHolder: String!\n  ) {\n    bankDetails: updateBankDetails(\n      iban: $iban\n      accountHolder: $accountHolder\n      bic: $bic\n      bankName: $bankName\n    ) {\n      iban\n      bic\n      accountHolder\n      bankName\n    }\n  }\n": types.UpdateUserBankDetailsDocument,
    "\n  query countries {\n    countries {\n      code\n      label\n    }\n  }\n": types.CountriesDocument,
    "\n  mutation updateUserKyc($nationality: String!, $dateOfBirth: DateTime!) {\n    user: updateUser(\n      data: { dateOfBirth: $dateOfBirth, nationality: $nationality }\n    ) {\n      id\n      nationality\n      dateOfBirth\n    }\n  }\n": types.UpdateUserKycDocument,
    "\n  mutation uploadKYC($data: KYCInput!) {\n    uploadKYC(data: $data)\n  }\n": types.UploadKycDocument,
    "\n  query getCards {\n    me {\n      cards {\n        id\n        maskedNumber\n        expirationDate\n        type\n        country\n        isDefault\n      }\n    }\n  }\n": types.GetCardsDocument,
    "\n  mutation changeCard($cardId: Int!) {\n    changeDefaultCard(cardId: $cardId)\n  }\n": types.ChangeCardDocument,
    "\n  mutation addCard {\n    paymentURL: addCard\n  }\n": types.AddCardDocument,
    "\n  mutation verifyNewCard($transactionId: String!) {\n    verifyNewCardTransaction(transactionId: $transactionId)\n  }\n": types.VerifyNewCardDocument,
    "\n  query getUserWalletDetails {\n    me {\n      totalBalance\n      balanceTransfered\n    }\n  }\n": types.GetUserWalletDetailsDocument,
    "\n  query userPaymentsTransactions(\n    $userId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    payments: userGeneralTransactions(\n      userId: $userId\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n": types.UserPaymentsTransactionsDocument,
    "\n  query userReferralsTransactions(\n    $receiverId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float\n  ) {\n    referrals: transactions(\n      where: { type: REFERRAL, receiverId: $receiverId }\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        avatar\n        fullName\n        email\n      }\n      amount\n      type\n    }\n  }\n": types.UserReferralsTransactionsDocument,
    "\n  mutation updateUser(\n    $firstName: String!\n    $lastName: String!\n    $zipCode: String!\n    $country: String!\n    $avatar: String!\n    $username: String!\n  ) {\n    updateUser(\n      data: {\n        firstName: $firstName\n        lastName: $lastName\n        zipCode: $zipCode\n        country: $country\n        avatar: $avatar\n        username: $username\n      }\n    ) {\n      firstName\n      lastName\n      country\n      zipCode\n      email\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation changePassword($password: String!) {\n    password: changePassword(password: $password) {\n      id\n    }\n  }\n": types.ChangePasswordDocument,
    "\n  mutation deleteUser {\n    deleteUser {\n      id\n    }\n  }\n": types.DeleteUserDocument,
    "\n  query serviceChatMessages(\n    $serviceId: String!\n    $skip: Float\n    $after: String\n    $first: Float!\n  ) {\n    chatMessages: getChatMessages(\n      serviceId: $serviceId\n      skip: $skip\n      after: $after\n      take: $first\n    ) {\n      id\n      content\n      createdAt\n      userId\n      user {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n": types.ServiceChatMessagesDocument,
    "\n  mutation sendMessage($serviceId: String!, $content: String!) {\n    chatMessage: createChatMessage(serviceId: $serviceId, content: $content) {\n      id\n      content\n      userId\n      createdAt\n      __typename\n    }\n  }\n": types.SendMessageDocument,
    "\n  mutation markChatRead($chatMessageId: String!, $serviceId: String!) {\n    markRead: markChatMessageRead(\n      chatMessageId: $chatMessageId\n      serviceId: $serviceId\n    )\n  }\n": types.MarkChatReadDocument,
    "\n  query joinPaymentsTransactions(\n    $serviceId: String!\n    $senderId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    transactions(\n      where: { serviceId: $serviceId, senderId: $senderId, type: MONEY_IN }\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n": types.JoinPaymentsTransactionsDocument,
    "\n  query offerPaymentsTransactions(\n    $serviceId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    transactions: offerTransactions(\n      where: { serviceId: $serviceId }\n      orderBy: { createdAt: desc }\n      first: $first\n      skip: $skip\n      after: $after\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n": types.OfferPaymentsTransactionsDocument,
    "\n  query getBasicServiceDetails($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n      owner {\n        id\n        firstName\n        avatar\n      }\n      slots {\n        usedBy {\n          id\n          avatar\n        }\n      }\n    }\n  }\n": types.GetBasicServiceDetailsDocument,
    "\n  query getServiceDetails($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        firstName\n        fullName\n        avatar\n      }\n      provider {\n        name\n        website\n        price {\n          fullPrice\n        }\n        joinerEmailRequired\n      }\n      requests {\n        status\n        requestById\n        id\n        joinerEmail\n      }\n      slots {\n        status\n        usedById\n        paymentDueDate\n      }\n      credentials {\n        email\n        password\n      }\n    }\n  }\n": types.GetServiceDetailsDocument,
    "\n  mutation UpdateRequest(\n    $joinerEmail: String!\n    $requestId: String\n  ){\n    updateRequest(\n      data: {\n        joinerEmail:$joinerEmail\n      }\n      where: {\n        id:$requestId\n      }\n    ) {\n    joinerEmail\n  }\n}\n": types.UpdateRequestDocument,
    "\n  mutation cancelService($serviceId: String!, $feedback: String!) {\n    service: cancelService(serviceId: $serviceId, feedback: $feedback) {\n      id\n    }\n  }\n": types.CancelServiceDocument,
    "\n  mutation cancelRequest($serviceId: String!, $feedback: String!) {\n    service: cancelRequest(serviceId: $serviceId, feedback: $feedback) {\n      id\n    }\n  }\n": types.CancelRequestDocument,
    "\n  query serviceCredentials($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        name\n        website\n        joinerEmailRequired\n      }\n      credentials {\n        email\n        password\n      }\n    }\n  }\n": types.ServiceCredentialsDocument,
    "\n  query GetRequestsWithSlots($serviceId: String!) {\n    getRequestsWithSlots(serviceId: $serviceId) {\n      id\n      associatedSlot {\n        status,\n        usedBy {\n          firstName\n          lastName\n          avatar\n        }\n      }\n      joinerEmail\n    }\n  }\n": types.GetRequestsWithSlotsDocument,
    "\n  mutation updateServiceAuth(\n    $serviceId: String!\n    $email: String!\n    $password: String!\n  ) {\n    credentials: updateService(\n      data: { serviceEmail: $email, servicePassword: $password }\n      where: { id: $serviceId }\n    ) {\n      email: serviceEmail\n      password: servicePassword\n    }\n  }\n": types.UpdateServiceAuthDocument,
    "\n  query serviceProviderName($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n    }\n  }\n": types.ServiceProviderNameDocument,
    "\n  mutation deleteService($serviceId: String!) {\n    service: deleteService(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n    }\n  }\n": types.DeleteServiceDocument,
    "\n  query getSlots($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n      provider {\n        numberOfSlots\n        price {\n          offerReturn\n        }\n      }\n      slots {\n        id\n        usedBy {\n          fullName\n          avatar\n        }\n        status\n        paymentDueDate\n        cancelledByJoiner\n      }\n    }\n  }\n": types.GetSlotsDocument,
    "\n  query getServiceOverview($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n      provider {\n        name\n        numberOfSlots\n      }\n      requests {\n        status\n      }\n    }\n  }\n": types.GetServiceOverviewDocument,
    "\n  query serviceAccountDetailsForReactivation($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        price {\n          offerReturn\n        }\n      }\n      slots {\n        id\n        status\n        usedBy {\n          id\n        }\n      }\n    }\n  }\n": types.ServiceAccountDetailsForReactivationDocument,
    "\n  mutation unreserveSlot($slotId: String!) {\n    freeSlot(slotId: $slotId) {\n      id\n    }\n  }\n": types.UnreserveSlotDocument,
    "\n  query serviceOfferReturn($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        price {\n          offerReturn\n        }\n      }\n    }\n  }\n": types.ServiceOfferReturnDocument,
    "\n  mutation reserveSlot($serviceId: String!) {\n    reserveSlot(serviceId: $serviceId) {\n      id\n    }\n  }\n": types.ReserveSlotDocument,
    "\n  query slotDetails($slotId: ID!) {\n    slot(where: { id: $slotId }) {\n      paymentDueDate\n      service {\n        provider {\n          price {\n            offerReturn\n          }\n        }\n      }\n      usedBy {\n        fullName\n        avatar\n      }\n    }\n  }\n": types.SlotDetailsDocument,
    "\n  mutation blockUser($slotId: String!, $feedback: String!) {\n    block(slotId: $slotId, feedback: $feedback) {\n      id\n    }\n  }\n": types.BlockUserDocument,
    "\n  mutation refundUser($slotId: String!, $feedback: String!) {\n    refund(slotId: $slotId, feedback: $feedback) {\n      id\n    }\n  }\n": types.RefundUserDocument,
    "\n  mutation reactivateSlot($slotId: String!) {\n    reactivate(slotId: $slotId) {\n      id\n    }\n  }\n": types.ReactivateSlotDocument,
    "\n  query allPendingRequestsFromService(\n    $serviceId: String!\n    $skip: Float\n    $after: RequestWhereUniqueInput\n    $first: Float!\n  ) {\n    requests: requests(\n      where: { serviceId: $serviceId, status: PENDING }\n      skip: $skip\n      after: $after\n      first: $first\n      orderBy: { createdAt: desc }\n    ) {\n      id\n      requestBy {\n        avatar\n        fullName\n      }\n      createdAt\n      isLastRequest\n    }\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n    }\n  }\n": types.AllPendingRequestsFromServiceDocument,
    "\n  query getPendingRequestForAcceptPopup($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      joinerEmail\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n          joinerEmailRequired\n          website\n        }\n      }\n      requestBy {\n        fullName\n        avatar\n      }\n    }\n  }\n": types.GetPendingRequestForAcceptPopupDocument,
    "\n  mutation acceptRequest($requestId: String!) {\n    acceptRequest(requestId: $requestId) {\n      id\n    }\n  }\n": types.AcceptRequestDocument,
    "\n  query getRequestDetailsToAccept($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n        }\n      }\n      requestBy {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n": types.GetRequestDetailsToAcceptDocument,
    "\n  query getRequestDetailsForCreateAccount($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n          website\n        }\n      }\n      requestBy {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n": types.GetRequestDetailsForCreateAccountDocument,
    "\n  query getRequestDetailsToDecline($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          price {\n            offerReturn\n          }\n        }\n      }\n      requestBy {\n        fullName\n        avatar\n      }\n    }\n  }\n": types.GetRequestDetailsToDeclineDocument,
    "\n  mutation rejectRequest($requestId: String!) {\n    rejectRequest(requestId: $requestId) {\n      id\n    }\n  }\n": types.RejectRequestDocument,
    "\n  query getServices {\n    services: me {\n      id\n      servicesOwned {\n        id\n        provider {\n          id\n          name\n          brand\n          price {\n            id\n            commission\n            offerReturn\n            fullPrice\n          }\n          numberOfSlots\n        }\n        numFreeSlots\n        slots {\n          id\n          status\n        }\n      }\n      servicesJoined {\n        id\n        owner {\n          id\n          firstName\n        }\n        provider {\n          id\n          name\n          brand\n          price {\n            id\n            fullPrice\n          }\n        }\n      }\n    }\n  }\n": types.GetServicesDocument,
    "\n  query getProviderDetailsForJoinPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      name\n      status\n      brand\n    }\n  }\n": types.GetProviderDetailsForJoinPopupDocument,
    "\n  query getServiceDetailsForJoinError($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        avatar\n      }\n    }\n  }\n": types.GetServiceDetailsForJoinErrorDocument,
    "\n  query getServiceDetailsForJoinPayment($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        firstName\n        avatar\n      }\n      provider {\n        name\n        brand\n      }\n    }\n  }\n": types.GetServiceDetailsForJoinPaymentDocument,
    "\n  mutation preAuth($serviceId: String!) {\n    token: preAuth(service: { id: $serviceId }) {\n      webInitUrl\n    }\n  }\n": types.PreAuthDocument,
    "\n  query servicesAvailableToJoin($providerId: String) {\n    services: servicesNotOwnerOrJoined(providerId: $providerId) {\n      id\n      slug\n      provider {\n        id\n        name\n      }\n      owner {\n        id\n        fullName\n        avatar\n        slug\n      }\n    }\n  }\n": types.ServicesAvailableToJoinDocument,
    "\n  query getServiceDetailsForJoinSuccess($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        firstName\n        avatar\n      }\n      provider {\n        name\n        brand\n        joinerEmailRequired\n      }\n    }\n  }\n": types.GetServiceDetailsForJoinSuccessDocument,
    "\n  mutation JoinService(\n    $serviceId: String!\n    $requestById: String!\n    $transactionId: String!\n  ) {\n    join(\n      data: {\n        serviceId: $serviceId\n        requestById: $requestById\n        transactionId: $transactionId\n      }\n    ) {\n      id\n      requestBy {\n        email\n      }\n    }\n  }\n": types.JoinServiceDocument,
    "\n    mutation UpdateRequest(\n      $joinerEmail: String!\n      $requestId: String\n    ){\n      updateRequest(\n        data: {\n          joinerEmail:$joinerEmail\n        }\n        where: {\n          id:$requestId\n        }\n      ) {\n      joinerEmail\n    }\n  }\n": types.UpdateRequestDocument,
    "\n  query getProviderDetailsForOfferPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      website\n      numberOfSlots\n    }\n  }\n": types.GetProviderDetailsForOfferPopupDocument,
    "\n  query getProviderDetailsForOfferDetailsPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      website\n      numberOfSlots\n      joinerEmailRequired\n    }\n  }\n": types.GetProviderDetailsForOfferDetailsPopupDocument,
    "\n  mutation offerService($data: ServiceOfferCreateInput!) {\n    offer(data: $data) {\n      id\n      slots {\n        status\n      }\n    }\n  }\n": types.OfferServiceDocument,
    "\n  query getProviderRequest {\n    providerRequests {\n      id\n      name\n    }\n  }\n": types.GetProviderRequestDocument,
    "\n  mutation createProviderRequest($name: String!) {\n    createProviderRequest(name: $name) {\n      id\n    }\n  }\n": types.CreateProviderRequestDocument,
    "\n  query getAllProviders {\n    providers {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      brand\n      numberOfSlots\n    }\n  }\n": types.GetAllProvidersDocument,
    "\n  query getProviderDetailsForSidebar($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      price {\n        id\n        offerReturn\n        fullPrice\n      }\n      brand\n      numberOfSlots\n    }\n  }\n": types.GetProviderDetailsForSidebarDocument,
    "\n  query getMe {\n    user: me {\n      id\n      username\n      firstName\n      lastName\n      fullName\n      email\n      slug\n      avatar\n      referralCode\n      zipCode\n      country\n      balanceAvailable\n      balanceTransfered\n      totalBalance\n      walletStatus\n      referralTotalEarns\n      ibanStatus\n      idDocumentsStatus\n      ibanStatus\n      documentsStatus\n      firstDocumentStatus\n      firstDocumentType\n      secondDocumentStatus\n      secondDocumentType\n      nationality\n      dateOfBirth\n    }\n  }\n": types.GetMeDocument,
    "\n  subscription user {\n    user: subscribeMe {\n      id\n      username\n      firstName\n      lastName\n      fullName\n      email\n      slug\n      avatar\n      referralCode\n      zipCode\n      country\n      balanceAvailable\n      balanceTransfered\n      totalBalance\n      walletStatus\n      referralTotalEarns\n      ibanStatus\n      idDocumentsStatus\n      ibanStatus\n      documentsStatus\n      firstDocumentStatus\n      firstDocumentType\n      secondDocumentStatus\n      secondDocumentType\n      nationality\n      dateOfBirth\n    }\n  }\n": types.UserDocument,
    "\n  mutation login($email: String!, $password: String!) {\n    login(email: $email, password: $password) {\n      user\n      accessToken\n      refreshToken\n    }\n  }\n": types.LoginDocument,
    "\n  mutation socialLogin($provider: SocialAuthProviders!, $token: String!) {\n    socialLogin(provider: $provider, token: $token) {\n      user\n      accessToken\n      refreshToken\n    }\n  }\n": types.SocialLoginDocument,
    "\n  mutation signup(\n    $firstName: String!\n    $lastName: String!\n    $email: String!\n    $password: String!\n    $avatar: String!\n    $referrerCode: String\n    $source: String!\n  ) {\n    signUp(\n      data: {\n        firstName: $firstName\n        lastName: $lastName\n        email: $email\n        password: $password\n        avatar: $avatar\n        referrerCode: $referrerCode\n        source: $source\n      }\n    )\n  }\n": types.SignupDocument,
    "\n  subscription chatMessage($serviceId: String!) {\n    chatMessage: subscribeChatMessage(serviceId: $serviceId) {\n      id\n      content\n      createdAt\n      userId\n      user {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n": types.ChatMessageDocument,
    "\n  query GetUnreadCount($serviceId: String!) {\n    unreadCount: getUnreadChatMessagesCount(serviceId: $serviceId)\n  }\n": types.GetUnreadCountDocument,
    "\n  query getServiceIds {\n    services: me {\n      servicesOwned {\n        id\n      }\n      servicesJoined {\n        id\n      }\n    }\n  }\n": types.GetServiceIdsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation moneyOut($userId: String!) {\n    money: moneyOut(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation moneyOut($userId: String!) {\n    money: moneyOut(userId: $userId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation forgotPassword($email: String!) {\n    forgot: forgotPassword(email: $email)\n  }\n"): (typeof documents)["\n  mutation forgotPassword($email: String!) {\n    forgot: forgotPassword(email: $email)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation resetPassword($token: String!, $password: String!) {\n    password: changePassword(token: $token, password: $password) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation resetPassword($token: String!, $password: String!) {\n    password: changePassword(token: $token, password: $password) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation validateEmail($token: String!) {\n    user: validateEmailByToken(token: $token) {\n      validation\n    }\n  }\n"): (typeof documents)["\n  mutation validateEmail($token: String!) {\n    user: validateEmailByToken(token: $token) {\n      validation\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getBankDetails {\n    me {\n      bankDetails {\n        iban\n        bic\n        accountHolder\n        bankName\n      }\n      documentsStatus\n      firstDocumentType\n      firstDocumentStatus\n      ibanStatus\n    }\n  }\n"): (typeof documents)["\n  query getBankDetails {\n    me {\n      bankDetails {\n        iban\n        bic\n        accountHolder\n        bankName\n      }\n      documentsStatus\n      firstDocumentType\n      firstDocumentStatus\n      ibanStatus\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUserBankDetails(\n    $bankName: String!\n    $iban: String!\n    $bic: String!\n    $accountHolder: String!\n  ) {\n    bankDetails: updateBankDetails(\n      iban: $iban\n      accountHolder: $accountHolder\n      bic: $bic\n      bankName: $bankName\n    ) {\n      iban\n      bic\n      accountHolder\n      bankName\n    }\n  }\n"): (typeof documents)["\n  mutation updateUserBankDetails(\n    $bankName: String!\n    $iban: String!\n    $bic: String!\n    $accountHolder: String!\n  ) {\n    bankDetails: updateBankDetails(\n      iban: $iban\n      accountHolder: $accountHolder\n      bic: $bic\n      bankName: $bankName\n    ) {\n      iban\n      bic\n      accountHolder\n      bankName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query countries {\n    countries {\n      code\n      label\n    }\n  }\n"): (typeof documents)["\n  query countries {\n    countries {\n      code\n      label\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUserKyc($nationality: String!, $dateOfBirth: DateTime!) {\n    user: updateUser(\n      data: { dateOfBirth: $dateOfBirth, nationality: $nationality }\n    ) {\n      id\n      nationality\n      dateOfBirth\n    }\n  }\n"): (typeof documents)["\n  mutation updateUserKyc($nationality: String!, $dateOfBirth: DateTime!) {\n    user: updateUser(\n      data: { dateOfBirth: $dateOfBirth, nationality: $nationality }\n    ) {\n      id\n      nationality\n      dateOfBirth\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation uploadKYC($data: KYCInput!) {\n    uploadKYC(data: $data)\n  }\n"): (typeof documents)["\n  mutation uploadKYC($data: KYCInput!) {\n    uploadKYC(data: $data)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getCards {\n    me {\n      cards {\n        id\n        maskedNumber\n        expirationDate\n        type\n        country\n        isDefault\n      }\n    }\n  }\n"): (typeof documents)["\n  query getCards {\n    me {\n      cards {\n        id\n        maskedNumber\n        expirationDate\n        type\n        country\n        isDefault\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation changeCard($cardId: Int!) {\n    changeDefaultCard(cardId: $cardId)\n  }\n"): (typeof documents)["\n  mutation changeCard($cardId: Int!) {\n    changeDefaultCard(cardId: $cardId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addCard {\n    paymentURL: addCard\n  }\n"): (typeof documents)["\n  mutation addCard {\n    paymentURL: addCard\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation verifyNewCard($transactionId: String!) {\n    verifyNewCardTransaction(transactionId: $transactionId)\n  }\n"): (typeof documents)["\n  mutation verifyNewCard($transactionId: String!) {\n    verifyNewCardTransaction(transactionId: $transactionId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getUserWalletDetails {\n    me {\n      totalBalance\n      balanceTransfered\n    }\n  }\n"): (typeof documents)["\n  query getUserWalletDetails {\n    me {\n      totalBalance\n      balanceTransfered\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userPaymentsTransactions(\n    $userId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    payments: userGeneralTransactions(\n      userId: $userId\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n"): (typeof documents)["\n  query userPaymentsTransactions(\n    $userId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    payments: userGeneralTransactions(\n      userId: $userId\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userReferralsTransactions(\n    $receiverId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float\n  ) {\n    referrals: transactions(\n      where: { type: REFERRAL, receiverId: $receiverId }\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        avatar\n        fullName\n        email\n      }\n      amount\n      type\n    }\n  }\n"): (typeof documents)["\n  query userReferralsTransactions(\n    $receiverId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float\n  ) {\n    referrals: transactions(\n      where: { type: REFERRAL, receiverId: $receiverId }\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        avatar\n        fullName\n        email\n      }\n      amount\n      type\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUser(\n    $firstName: String!\n    $lastName: String!\n    $zipCode: String!\n    $country: String!\n    $avatar: String!\n    $username: String!\n  ) {\n    updateUser(\n      data: {\n        firstName: $firstName\n        lastName: $lastName\n        zipCode: $zipCode\n        country: $country\n        avatar: $avatar\n        username: $username\n      }\n    ) {\n      firstName\n      lastName\n      country\n      zipCode\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation updateUser(\n    $firstName: String!\n    $lastName: String!\n    $zipCode: String!\n    $country: String!\n    $avatar: String!\n    $username: String!\n  ) {\n    updateUser(\n      data: {\n        firstName: $firstName\n        lastName: $lastName\n        zipCode: $zipCode\n        country: $country\n        avatar: $avatar\n        username: $username\n      }\n    ) {\n      firstName\n      lastName\n      country\n      zipCode\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation changePassword($password: String!) {\n    password: changePassword(password: $password) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation changePassword($password: String!) {\n    password: changePassword(password: $password) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteUser {\n    deleteUser {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deleteUser {\n    deleteUser {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query serviceChatMessages(\n    $serviceId: String!\n    $skip: Float\n    $after: String\n    $first: Float!\n  ) {\n    chatMessages: getChatMessages(\n      serviceId: $serviceId\n      skip: $skip\n      after: $after\n      take: $first\n    ) {\n      id\n      content\n      createdAt\n      userId\n      user {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  query serviceChatMessages(\n    $serviceId: String!\n    $skip: Float\n    $after: String\n    $first: Float!\n  ) {\n    chatMessages: getChatMessages(\n      serviceId: $serviceId\n      skip: $skip\n      after: $after\n      take: $first\n    ) {\n      id\n      content\n      createdAt\n      userId\n      user {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation sendMessage($serviceId: String!, $content: String!) {\n    chatMessage: createChatMessage(serviceId: $serviceId, content: $content) {\n      id\n      content\n      userId\n      createdAt\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation sendMessage($serviceId: String!, $content: String!) {\n    chatMessage: createChatMessage(serviceId: $serviceId, content: $content) {\n      id\n      content\n      userId\n      createdAt\n      __typename\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation markChatRead($chatMessageId: String!, $serviceId: String!) {\n    markRead: markChatMessageRead(\n      chatMessageId: $chatMessageId\n      serviceId: $serviceId\n    )\n  }\n"): (typeof documents)["\n  mutation markChatRead($chatMessageId: String!, $serviceId: String!) {\n    markRead: markChatMessageRead(\n      chatMessageId: $chatMessageId\n      serviceId: $serviceId\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query joinPaymentsTransactions(\n    $serviceId: String!\n    $senderId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    transactions(\n      where: { serviceId: $serviceId, senderId: $senderId, type: MONEY_IN }\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n"): (typeof documents)["\n  query joinPaymentsTransactions(\n    $serviceId: String!\n    $senderId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    transactions(\n      where: { serviceId: $serviceId, senderId: $senderId, type: MONEY_IN }\n      orderBy: { createdAt: desc }\n      skip: $skip\n      after: $after\n      first: $first\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query offerPaymentsTransactions(\n    $serviceId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    transactions: offerTransactions(\n      where: { serviceId: $serviceId }\n      orderBy: { createdAt: desc }\n      first: $first\n      skip: $skip\n      after: $after\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n"): (typeof documents)["\n  query offerPaymentsTransactions(\n    $serviceId: String!\n    $skip: Float\n    $after: TransactionWhereUniqueInput\n    $first: Float!\n  ) {\n    transactions: offerTransactions(\n      where: { serviceId: $serviceId }\n      orderBy: { createdAt: desc }\n      first: $first\n      skip: $skip\n      after: $after\n    ) {\n      id\n      sender {\n        id\n        avatar\n        fullName\n      }\n      receiver {\n        id\n        avatar\n        fullName\n      }\n      amount\n      type\n      service {\n        provider {\n          name\n        }\n      }\n      createdAt\n      commission\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getBasicServiceDetails($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n      owner {\n        id\n        firstName\n        avatar\n      }\n      slots {\n        usedBy {\n          id\n          avatar\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getBasicServiceDetails($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n      owner {\n        id\n        firstName\n        avatar\n      }\n      slots {\n        usedBy {\n          id\n          avatar\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getServiceDetails($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        firstName\n        fullName\n        avatar\n      }\n      provider {\n        name\n        website\n        price {\n          fullPrice\n        }\n        joinerEmailRequired\n      }\n      requests {\n        status\n        requestById\n        id\n        joinerEmail\n      }\n      slots {\n        status\n        usedById\n        paymentDueDate\n      }\n      credentials {\n        email\n        password\n      }\n    }\n  }\n"): (typeof documents)["\n  query getServiceDetails($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        firstName\n        fullName\n        avatar\n      }\n      provider {\n        name\n        website\n        price {\n          fullPrice\n        }\n        joinerEmailRequired\n      }\n      requests {\n        status\n        requestById\n        id\n        joinerEmail\n      }\n      slots {\n        status\n        usedById\n        paymentDueDate\n      }\n      credentials {\n        email\n        password\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateRequest(\n    $joinerEmail: String!\n    $requestId: String\n  ){\n    updateRequest(\n      data: {\n        joinerEmail:$joinerEmail\n      }\n      where: {\n        id:$requestId\n      }\n    ) {\n    joinerEmail\n  }\n}\n"): (typeof documents)["\n  mutation UpdateRequest(\n    $joinerEmail: String!\n    $requestId: String\n  ){\n    updateRequest(\n      data: {\n        joinerEmail:$joinerEmail\n      }\n      where: {\n        id:$requestId\n      }\n    ) {\n    joinerEmail\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation cancelService($serviceId: String!, $feedback: String!) {\n    service: cancelService(serviceId: $serviceId, feedback: $feedback) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation cancelService($serviceId: String!, $feedback: String!) {\n    service: cancelService(serviceId: $serviceId, feedback: $feedback) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation cancelRequest($serviceId: String!, $feedback: String!) {\n    service: cancelRequest(serviceId: $serviceId, feedback: $feedback) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation cancelRequest($serviceId: String!, $feedback: String!) {\n    service: cancelRequest(serviceId: $serviceId, feedback: $feedback) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query serviceCredentials($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        name\n        website\n        joinerEmailRequired\n      }\n      credentials {\n        email\n        password\n      }\n    }\n  }\n"): (typeof documents)["\n  query serviceCredentials($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        name\n        website\n        joinerEmailRequired\n      }\n      credentials {\n        email\n        password\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRequestsWithSlots($serviceId: String!) {\n    getRequestsWithSlots(serviceId: $serviceId) {\n      id\n      associatedSlot {\n        status,\n        usedBy {\n          firstName\n          lastName\n          avatar\n        }\n      }\n      joinerEmail\n    }\n  }\n"): (typeof documents)["\n  query GetRequestsWithSlots($serviceId: String!) {\n    getRequestsWithSlots(serviceId: $serviceId) {\n      id\n      associatedSlot {\n        status,\n        usedBy {\n          firstName\n          lastName\n          avatar\n        }\n      }\n      joinerEmail\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateServiceAuth(\n    $serviceId: String!\n    $email: String!\n    $password: String!\n  ) {\n    credentials: updateService(\n      data: { serviceEmail: $email, servicePassword: $password }\n      where: { id: $serviceId }\n    ) {\n      email: serviceEmail\n      password: servicePassword\n    }\n  }\n"): (typeof documents)["\n  mutation updateServiceAuth(\n    $serviceId: String!\n    $email: String!\n    $password: String!\n  ) {\n    credentials: updateService(\n      data: { serviceEmail: $email, servicePassword: $password }\n      where: { id: $serviceId }\n    ) {\n      email: serviceEmail\n      password: servicePassword\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query serviceProviderName($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n    }\n  }\n"): (typeof documents)["\n  query serviceProviderName($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteService($serviceId: String!) {\n    service: deleteService(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteService($serviceId: String!) {\n    service: deleteService(where: { id: $serviceId }) {\n      provider {\n        name\n        brand\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getSlots($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n      provider {\n        numberOfSlots\n        price {\n          offerReturn\n        }\n      }\n      slots {\n        id\n        usedBy {\n          fullName\n          avatar\n        }\n        status\n        paymentDueDate\n        cancelledByJoiner\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSlots($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n      provider {\n        numberOfSlots\n        price {\n          offerReturn\n        }\n      }\n      slots {\n        id\n        usedBy {\n          fullName\n          avatar\n        }\n        status\n        paymentDueDate\n        cancelledByJoiner\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getServiceOverview($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n      provider {\n        name\n        numberOfSlots\n      }\n      requests {\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  query getServiceOverview($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n      provider {\n        name\n        numberOfSlots\n      }\n      requests {\n        status\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query serviceAccountDetailsForReactivation($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        price {\n          offerReturn\n        }\n      }\n      slots {\n        id\n        status\n        usedBy {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query serviceAccountDetailsForReactivation($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        price {\n          offerReturn\n        }\n      }\n      slots {\n        id\n        status\n        usedBy {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation unreserveSlot($slotId: String!) {\n    freeSlot(slotId: $slotId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation unreserveSlot($slotId: String!) {\n    freeSlot(slotId: $slotId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query serviceOfferReturn($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        price {\n          offerReturn\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query serviceOfferReturn($serviceId: String!) {\n    service(where: { id: $serviceId }) {\n      provider {\n        price {\n          offerReturn\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation reserveSlot($serviceId: String!) {\n    reserveSlot(serviceId: $serviceId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation reserveSlot($serviceId: String!) {\n    reserveSlot(serviceId: $serviceId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query slotDetails($slotId: ID!) {\n    slot(where: { id: $slotId }) {\n      paymentDueDate\n      service {\n        provider {\n          price {\n            offerReturn\n          }\n        }\n      }\n      usedBy {\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  query slotDetails($slotId: ID!) {\n    slot(where: { id: $slotId }) {\n      paymentDueDate\n      service {\n        provider {\n          price {\n            offerReturn\n          }\n        }\n      }\n      usedBy {\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation blockUser($slotId: String!, $feedback: String!) {\n    block(slotId: $slotId, feedback: $feedback) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation blockUser($slotId: String!, $feedback: String!) {\n    block(slotId: $slotId, feedback: $feedback) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation refundUser($slotId: String!, $feedback: String!) {\n    refund(slotId: $slotId, feedback: $feedback) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation refundUser($slotId: String!, $feedback: String!) {\n    refund(slotId: $slotId, feedback: $feedback) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation reactivateSlot($slotId: String!) {\n    reactivate(slotId: $slotId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation reactivateSlot($slotId: String!) {\n    reactivate(slotId: $slotId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query allPendingRequestsFromService(\n    $serviceId: String!\n    $skip: Float\n    $after: RequestWhereUniqueInput\n    $first: Float!\n  ) {\n    requests: requests(\n      where: { serviceId: $serviceId, status: PENDING }\n      skip: $skip\n      after: $after\n      first: $first\n      orderBy: { createdAt: desc }\n    ) {\n      id\n      requestBy {\n        avatar\n        fullName\n      }\n      createdAt\n      isLastRequest\n    }\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n    }\n  }\n"): (typeof documents)["\n  query allPendingRequestsFromService(\n    $serviceId: String!\n    $skip: Float\n    $after: RequestWhereUniqueInput\n    $first: Float!\n  ) {\n    requests: requests(\n      where: { serviceId: $serviceId, status: PENDING }\n      skip: $skip\n      after: $after\n      first: $first\n      orderBy: { createdAt: desc }\n    ) {\n      id\n      requestBy {\n        avatar\n        fullName\n      }\n      createdAt\n      isLastRequest\n    }\n    service: service(where: { id: $serviceId }) {\n      numFreeSlots\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getPendingRequestForAcceptPopup($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      joinerEmail\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n          joinerEmailRequired\n          website\n        }\n      }\n      requestBy {\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPendingRequestForAcceptPopup($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      joinerEmail\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n          joinerEmailRequired\n          website\n        }\n      }\n      requestBy {\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation acceptRequest($requestId: String!) {\n    acceptRequest(requestId: $requestId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation acceptRequest($requestId: String!) {\n    acceptRequest(requestId: $requestId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getRequestDetailsToAccept($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n        }\n      }\n      requestBy {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  query getRequestDetailsToAccept($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n        }\n      }\n      requestBy {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getRequestDetailsForCreateAccount($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n          website\n        }\n      }\n      requestBy {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  query getRequestDetailsForCreateAccount($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          name\n          price {\n            offerReturn\n          }\n          website\n        }\n      }\n      requestBy {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getRequestDetailsToDecline($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          price {\n            offerReturn\n          }\n        }\n      }\n      requestBy {\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  query getRequestDetailsToDecline($requestId: String!) {\n    request(where: { id: $requestId }) {\n      isLastRequest\n      service {\n        provider {\n          price {\n            offerReturn\n          }\n        }\n      }\n      requestBy {\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation rejectRequest($requestId: String!) {\n    rejectRequest(requestId: $requestId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation rejectRequest($requestId: String!) {\n    rejectRequest(requestId: $requestId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getServices {\n    services: me {\n      id\n      servicesOwned {\n        id\n        provider {\n          id\n          name\n          brand\n          price {\n            id\n            commission\n            offerReturn\n            fullPrice\n          }\n          numberOfSlots\n        }\n        numFreeSlots\n        slots {\n          id\n          status\n        }\n      }\n      servicesJoined {\n        id\n        owner {\n          id\n          firstName\n        }\n        provider {\n          id\n          name\n          brand\n          price {\n            id\n            fullPrice\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getServices {\n    services: me {\n      id\n      servicesOwned {\n        id\n        provider {\n          id\n          name\n          brand\n          price {\n            id\n            commission\n            offerReturn\n            fullPrice\n          }\n          numberOfSlots\n        }\n        numFreeSlots\n        slots {\n          id\n          status\n        }\n      }\n      servicesJoined {\n        id\n        owner {\n          id\n          firstName\n        }\n        provider {\n          id\n          name\n          brand\n          price {\n            id\n            fullPrice\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getProviderDetailsForJoinPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      name\n      status\n      brand\n    }\n  }\n"): (typeof documents)["\n  query getProviderDetailsForJoinPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      name\n      status\n      brand\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getServiceDetailsForJoinError($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  query getServiceDetailsForJoinError($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getServiceDetailsForJoinPayment($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        firstName\n        avatar\n      }\n      provider {\n        name\n        brand\n      }\n    }\n  }\n"): (typeof documents)["\n  query getServiceDetailsForJoinPayment($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        firstName\n        avatar\n      }\n      provider {\n        name\n        brand\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation preAuth($serviceId: String!) {\n    token: preAuth(service: { id: $serviceId }) {\n      webInitUrl\n    }\n  }\n"): (typeof documents)["\n  mutation preAuth($serviceId: String!) {\n    token: preAuth(service: { id: $serviceId }) {\n      webInitUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query servicesAvailableToJoin($providerId: String) {\n    services: servicesNotOwnerOrJoined(providerId: $providerId) {\n      id\n      slug\n      provider {\n        id\n        name\n      }\n      owner {\n        id\n        fullName\n        avatar\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  query servicesAvailableToJoin($providerId: String) {\n    services: servicesNotOwnerOrJoined(providerId: $providerId) {\n      id\n      slug\n      provider {\n        id\n        name\n      }\n      owner {\n        id\n        fullName\n        avatar\n        slug\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getServiceDetailsForJoinSuccess($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        firstName\n        avatar\n      }\n      provider {\n        name\n        brand\n        joinerEmailRequired\n      }\n    }\n  }\n"): (typeof documents)["\n  query getServiceDetailsForJoinSuccess($serviceId: String!) {\n    service: service(where: { id: $serviceId }) {\n      owner {\n        fullName\n        firstName\n        avatar\n      }\n      provider {\n        name\n        brand\n        joinerEmailRequired\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation JoinService(\n    $serviceId: String!\n    $requestById: String!\n    $transactionId: String!\n  ) {\n    join(\n      data: {\n        serviceId: $serviceId\n        requestById: $requestById\n        transactionId: $transactionId\n      }\n    ) {\n      id\n      requestBy {\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation JoinService(\n    $serviceId: String!\n    $requestById: String!\n    $transactionId: String!\n  ) {\n    join(\n      data: {\n        serviceId: $serviceId\n        requestById: $requestById\n        transactionId: $transactionId\n      }\n    ) {\n      id\n      requestBy {\n        email\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateRequest(\n      $joinerEmail: String!\n      $requestId: String\n    ){\n      updateRequest(\n        data: {\n          joinerEmail:$joinerEmail\n        }\n        where: {\n          id:$requestId\n        }\n      ) {\n      joinerEmail\n    }\n  }\n"): (typeof documents)["\n    mutation UpdateRequest(\n      $joinerEmail: String!\n      $requestId: String\n    ){\n      updateRequest(\n        data: {\n          joinerEmail:$joinerEmail\n        }\n        where: {\n          id:$requestId\n        }\n      ) {\n      joinerEmail\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getProviderDetailsForOfferPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      website\n      numberOfSlots\n    }\n  }\n"): (typeof documents)["\n  query getProviderDetailsForOfferPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      website\n      numberOfSlots\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getProviderDetailsForOfferDetailsPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      website\n      numberOfSlots\n      joinerEmailRequired\n    }\n  }\n"): (typeof documents)["\n  query getProviderDetailsForOfferDetailsPopup($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      website\n      numberOfSlots\n      joinerEmailRequired\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation offerService($data: ServiceOfferCreateInput!) {\n    offer(data: $data) {\n      id\n      slots {\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation offerService($data: ServiceOfferCreateInput!) {\n    offer(data: $data) {\n      id\n      slots {\n        status\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getProviderRequest {\n    providerRequests {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query getProviderRequest {\n    providerRequests {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createProviderRequest($name: String!) {\n    createProviderRequest(name: $name) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createProviderRequest($name: String!) {\n    createProviderRequest(name: $name) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAllProviders {\n    providers {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      brand\n      numberOfSlots\n    }\n  }\n"): (typeof documents)["\n  query getAllProviders {\n    providers {\n      id\n      name\n      type\n      status\n      price {\n        id\n        fullPrice\n        commission\n        offerReturn\n      }\n      brand\n      numberOfSlots\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getProviderDetailsForSidebar($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      price {\n        id\n        offerReturn\n        fullPrice\n      }\n      brand\n      numberOfSlots\n    }\n  }\n"): (typeof documents)["\n  query getProviderDetailsForSidebar($providerId: String!) {\n    provider(where: { id: $providerId }) {\n      id\n      name\n      price {\n        id\n        offerReturn\n        fullPrice\n      }\n      brand\n      numberOfSlots\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getMe {\n    user: me {\n      id\n      username\n      firstName\n      lastName\n      fullName\n      email\n      slug\n      avatar\n      referralCode\n      zipCode\n      country\n      balanceAvailable\n      balanceTransfered\n      totalBalance\n      walletStatus\n      referralTotalEarns\n      ibanStatus\n      idDocumentsStatus\n      ibanStatus\n      documentsStatus\n      firstDocumentStatus\n      firstDocumentType\n      secondDocumentStatus\n      secondDocumentType\n      nationality\n      dateOfBirth\n    }\n  }\n"): (typeof documents)["\n  query getMe {\n    user: me {\n      id\n      username\n      firstName\n      lastName\n      fullName\n      email\n      slug\n      avatar\n      referralCode\n      zipCode\n      country\n      balanceAvailable\n      balanceTransfered\n      totalBalance\n      walletStatus\n      referralTotalEarns\n      ibanStatus\n      idDocumentsStatus\n      ibanStatus\n      documentsStatus\n      firstDocumentStatus\n      firstDocumentType\n      secondDocumentStatus\n      secondDocumentType\n      nationality\n      dateOfBirth\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription user {\n    user: subscribeMe {\n      id\n      username\n      firstName\n      lastName\n      fullName\n      email\n      slug\n      avatar\n      referralCode\n      zipCode\n      country\n      balanceAvailable\n      balanceTransfered\n      totalBalance\n      walletStatus\n      referralTotalEarns\n      ibanStatus\n      idDocumentsStatus\n      ibanStatus\n      documentsStatus\n      firstDocumentStatus\n      firstDocumentType\n      secondDocumentStatus\n      secondDocumentType\n      nationality\n      dateOfBirth\n    }\n  }\n"): (typeof documents)["\n  subscription user {\n    user: subscribeMe {\n      id\n      username\n      firstName\n      lastName\n      fullName\n      email\n      slug\n      avatar\n      referralCode\n      zipCode\n      country\n      balanceAvailable\n      balanceTransfered\n      totalBalance\n      walletStatus\n      referralTotalEarns\n      ibanStatus\n      idDocumentsStatus\n      ibanStatus\n      documentsStatus\n      firstDocumentStatus\n      firstDocumentType\n      secondDocumentStatus\n      secondDocumentType\n      nationality\n      dateOfBirth\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation login($email: String!, $password: String!) {\n    login(email: $email, password: $password) {\n      user\n      accessToken\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  mutation login($email: String!, $password: String!) {\n    login(email: $email, password: $password) {\n      user\n      accessToken\n      refreshToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation socialLogin($provider: SocialAuthProviders!, $token: String!) {\n    socialLogin(provider: $provider, token: $token) {\n      user\n      accessToken\n      refreshToken\n    }\n  }\n"): (typeof documents)["\n  mutation socialLogin($provider: SocialAuthProviders!, $token: String!) {\n    socialLogin(provider: $provider, token: $token) {\n      user\n      accessToken\n      refreshToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation signup(\n    $firstName: String!\n    $lastName: String!\n    $email: String!\n    $password: String!\n    $avatar: String!\n    $referrerCode: String\n    $source: String!\n  ) {\n    signUp(\n      data: {\n        firstName: $firstName\n        lastName: $lastName\n        email: $email\n        password: $password\n        avatar: $avatar\n        referrerCode: $referrerCode\n        source: $source\n      }\n    )\n  }\n"): (typeof documents)["\n  mutation signup(\n    $firstName: String!\n    $lastName: String!\n    $email: String!\n    $password: String!\n    $avatar: String!\n    $referrerCode: String\n    $source: String!\n  ) {\n    signUp(\n      data: {\n        firstName: $firstName\n        lastName: $lastName\n        email: $email\n        password: $password\n        avatar: $avatar\n        referrerCode: $referrerCode\n        source: $source\n      }\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription chatMessage($serviceId: String!) {\n    chatMessage: subscribeChatMessage(serviceId: $serviceId) {\n      id\n      content\n      createdAt\n      userId\n      user {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription chatMessage($serviceId: String!) {\n    chatMessage: subscribeChatMessage(serviceId: $serviceId) {\n      id\n      content\n      createdAt\n      userId\n      user {\n        firstName\n        fullName\n        avatar\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUnreadCount($serviceId: String!) {\n    unreadCount: getUnreadChatMessagesCount(serviceId: $serviceId)\n  }\n"): (typeof documents)["\n  query GetUnreadCount($serviceId: String!) {\n    unreadCount: getUnreadChatMessagesCount(serviceId: $serviceId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getServiceIds {\n    services: me {\n      servicesOwned {\n        id\n      }\n      servicesJoined {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query getServiceIds {\n    services: me {\n      servicesOwned {\n        id\n      }\n      servicesJoined {\n        id\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;