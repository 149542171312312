import { BrokenHeart } from '@/assets/illustrations'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup'
import { AuthContext } from '@/providers/Auth/context'
import { googleLogout } from '@react-oauth/google'
import { T } from '@tolgee/react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

export const AccountRemoved: React.FC = () => {
  const navigate = useNavigate()
  const { logOut } = useContext(AuthContext)!
  const handleLogOut = () => {
    googleLogout()
    logOut()
    navigate('/')
  }
  return (
    <PopupSkeleton>
      <div className="w-[450px] h-full max-md:w-full p-10 text-center">
        <div className="flex justify-center mb-8">
          <img src={BrokenHeart} alt="" />
        </div>
        <h1 className="text-lg font-medium mb-4">
          <T keyName="components.settings.removeAccountSuccess.title" />
        </h1>
        <h6 className="text-sm text-gray-shuttle mb-4">
          <T keyName="components.settings.removeAccountSuccess.subTitle" />
        </h6>
        <h6 className="text-xs text-gray-shuttle mb-6">
          <T keyName="components.settings.removeAccountSuccess.info" />
        </h6>

        <div className="flex justify-center">
          <Button
            onClick={handleLogOut}
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white"
          >
            <T keyName="components.settings.removeAccountSuccess.btn" />
          </Button>
        </div>
      </div>
    </PopupSkeleton>
  )
}
